import './App.css';
import './Card.css';

import profilePicture from './images/profilePicture.jpg';

import { ReactComponent as GitHubIcon } from './icons/github.svg';
import { ReactComponent as LinkedInIcon } from './icons/linkedin.svg';
import { ReactComponent as EmailIcon } from './icons/email.svg';

import broadcastBot from './images/broadcastBot.png';
import icssc from './images/icssc.png';
import pieLab from './images/pieLab.png';
import messengerBlack from './images/messengerBlack.png';
import tapestry from './images/tapestry.png';

import cameraEmoji from './emojis/cameraEmoji.png';
import moonEmoji from './emojis/moonEmoji.png';
import runningEmoji from './emojis/runningEmoji.png';
import sunriseEmoji from './emojis/sunriseEmoji.png';

function App() {
  return (
    <div className="App">
      <div className="App-header-container">
        <header className="App-header">
          <img src={profilePicture} alt="Myself" />
          <div className="App-header-bio">
            <h4 className="App-header-text">HELLO, I'M</h4>
            <h1 className="App-header-text">Ryan Mohta</h1>
            <p className="App-header-text"><em>Front-End Developer and 2nd-year CS Student @ UC Irvine.</em></p>
            <div className="App-header-icons">
              <a href="https://github.com/ryanmohta"><GitHubIcon /></a>
              <a href="https://linkedin.com/in/ryanmohta"><LinkedInIcon /></a>
              <a href="mailto:mohtar@uci.edu"><EmailIcon className="App-header-emailIcon" /></a>
            </div>
          </div>
        </header>
      </div>


      <div className="App-section">
        <h2>RELEVANT EXPERIENCE</h2>
        <div className="App-section-container">
          <Card image={pieLab} name="Research Assistant" info="PIE Lab • Apr 2020 - Present" description={<p><ul><li>Developing a mobile app that dynamically generates Snapchat stickers based on health data such as steps taken, heart rate, and calories eaten.</li><li>Implemented the Ionic Angular framework to create a component-based, cross-platform experience for iOS and Android.</li></ul></p>} />
          <Card image={icssc} name="Projects Committee Member" info="ICS Student Council • Mar 2020 - Present" description={<p><ul><li>Working in a front-end development team for “PeterPortal,” an all-in-one course planning and scheduling website, using the React framework.</li><li>Designing components of the website’s user interface using Figma design software.</li></ul></p>} />
          <Card image={icssc} name="Events Committee Member" info="ICS Student Council • Oct 2019 - Present" description={<p><ul><li>Working in a committee to develop and facilitate events to bring the UCI Information and Computer Science (ICS) community together.</li><li>Events impact ICS students technically and socially, and include the Reverse Career Fair, Alumni Panel, and ICSSC x Design Online Design Challenge.</li></ul></p>} />
        </div>
      </div>

      <div className="App-section" id="App-projects">
        <h2>PROJECTS</h2>
        <div className="App-section-container">
          <Card image={messengerBlack} name="Messenger Black" info="Scheduled Dark Mode for the Messenger website" description={<p><ul><li>Created a macOS app and Chrome/Safari extension that brings Scheduled Dark Mode to Facebook Messenger on desktop. Users can manually toggle Dark Mode or automatically align it with sunset/sunrise or their own schedule.</li><li>Completed at the end of my senior year of high school; 45,000+ downloads across Safari and Chrome.</li></ul></p>} />
          <Card image={tapestry} name="Tapestry" info="Code Visualization for Python" description={<p><ul><li>Co-developing an online code visualization tool that creates easy-to-understand diagrams based on inputted Python code.</li><li>Won Best Entrepreneurial Hack award at HackUCI 2020, which included a $1,000 grant, $5,000 of AWS credit, and continued mentorship from 1517 Fund.</li><li>Working on the front-end of the project: created the website user interface/design, and wrote the script that sends Python code from the code editor to the backend.</li></ul></p>} />
          <Card image={broadcastBot} name="Broadcast Bot" info="Send @everyone messages on Messenger" description={<p><ul><li>Currently developing a Facebook Messenger bot that borrows the "@everyone" functionality from Slack and Discord.</li><li>Use the bot to notify everyone in a group chat of your message, even those who have muted the group chat.</li></ul></p>} />
        </div>
      </div>

      <div className="App-section" id="App-passions">
        <h2>WHAT ELSE DO I DO?</h2>
        <div className="App-section-container">
          <Card image={cameraEmoji} info="I LOVE photography, and have been doing it for many years! My favorite photos to take are portraits and nature." />
          <Card image={runningEmoji} info="I really enjoy running, and was part of my middle and high school track and field team as a distance runner." />
          <Card image={moonEmoji} info="I'm a huge dark mode enthusiast, a big reason why I decided to base my first published project (Messenger Black) around it!" />
          <Card image={sunriseEmoji} info="When it's not dark outside, however, I love watching sunsets and am intrigued by the sun cycle throughout the day." />
        </div>
      </div>
    </div>
  );
}

function Card(props) {
  return (
    <div className="Card">
      <div className="Card-header">
        <img src={props.image} alt={`${props.info} logo`} />
        <div className="Card-headerText">
          <h3>{props.name}</h3>
          <h5>{props.info}</h5>
        </div>
      </div>

      {props.description}
    </div>
  );
}

export default App;
